<div class="container-fluid bg-light">
    <div class="row py-3">
        <div class="col-md-8">
            <h1>Admin Overview</h1>
        </div>
        <div class="col-md-4 text-right">
            <a routerLink="/edit" class="btn btn-success">Create New</a>
        </div>
    </div>

    <div class="row py-3">
        <div class="col-md-3 mb-3">
            <div class="card">
                <div class="card-body text-primary">
                    <i class="fas fa-calendar"></i>
                    <p class="muted">
                        Nr of Events
                    </p>
                    <p class="lead">
                        {{events.length}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-3">
            <div class="card">
                <div class="card-body text-primary">
                    <i class="fas fa-sign-in-alt"></i>
                    <p class="muted">
                        Nr of Registrations
                    </p>
                    <p class="lead">
                        {{registrations.length}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-3">
            <div class="card">
                <div class="card-body text-primary">
                    <i class="fas fa-envelope"></i>
                    <p class="muted">
                        Nr of Verified E-Mails
                    </p>
                    <p class="lead">
                        {{nrOfRegisteredUser()}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-3">
            <div class="card">
                <div class="card-body text-primary">
                    <i class="fas fa-window-close"></i>
                    <p class="muted">
                        Nr of Deregistrations
                    </p>
                    <p class="lead">
                        {{nrOfDeregistrations()}}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="row py-3">
        <div class="col-12 mb-3">
            <div class="row">
                <div class="col-md-6 mb-3" *ngFor="let obj of eventMap | keyvalue">
                    <div class="card">
                        <div class="card-body">
                            <small>{{getTimeText(obj.value.event.start)}}</small>
                            <h5 class="card-title">{{obj.value.event.title}} {{formatDate(obj.value.event.start)}} {{formatTime(obj.value.event.start)}}-{{formatTime(obj.value.event.end)}}</h5>
                            <a routerLink="/edit/{{obj.value.event.key}}" class="btn btn-sm btn-info">Update</a>
                            <button (click)="delete(obj.value.event.key)" class="btn btn-sm btn-danger ml-3">Delete</button>
                            <table class="table mt-3" *ngIf="obj.value.registrations.length">
                                <thead>
                                    <tr>
                                        <th scope="col">E-Mail</th>
                                        <th scope="col">Sent</th>
                                        <th scope="col">Verified</th>
                                        <th scope="col">Deregistered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of obj.value.registrations">
                                        <th scope="row">{{r.email}}</th>
                                        <td>{{r.emailSent}}</td>
                                        <td>{{r.emailVerified}}</td>
                                        <td>{{r.deregistered}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>