import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/_services/event.service';
import { RegistrationService } from 'src/app/_services/registration.service';
import { Event } from 'src/app/_classes/event';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Registration } from 'src/app/_classes/registration';
import { AngularFireFunctions } from '@angular/fire/functions';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.css']
})
export class DetailViewComponent implements OnInit {

  public event: Event | null | undefined;
  private key;
  public registrationForm: FormGroup;
  private registration: Registration = {
    eventIDFS: '',
    email: '',
    emailSent: false, 
    emailVerified: false,
    deregistered: false
  };
  public submitted = false;
  public loading = false;
  public errorMessage: string | undefined;
  public successMessage: string | undefined;
  public registrations: any[] = [];
  public foundRegistrations: any[] = [];

  constructor(private anf: AngularFireFunctions, private registrationService: RegistrationService, private eventService: EventService, private route: ActivatedRoute, private formBuilder: FormBuilder) {
    this.key = this.route.snapshot.paramMap.get("id")!;
    this.registration.eventIDFS = this.key;
    this.eventService.get(this.key).subscribe((event) => {
      this.event = event;
    })
    this.registrationService.getFromEvent(this.key).pipe(take(1)).subscribe((registrations: any[]) => {
      this.registrations = registrations.filter(r => { return r.deregistered == false });
    })
    this.registrationForm = this.formBuilder.group({
      email: [this.registration.email, [Validators.required, 
        Validators.pattern(new RegExp('^[A-Za-z0-9-]*[.]{1}[A-Za-z0-9-]*@cognizant.com$', 'i'))
      ]]
    })
  }

  ngOnInit(): void {
  }

  public get rF() {
    return this.registrationForm.controls;
  }


  public submit() {
    this.submitted = true;
    this.loading = true;
    if (this.registrationForm.invalid) {
      this.loading = false;
      return;
    }
    this.registration.email = this.rF.email.value.toLowerCase();
    this.foundRegistrations = this.registrations.filter(r => { return r.email.toLowerCase() == this.registration.email.toLowerCase() });
    if(this.foundRegistrations.length != 0) {
      this.errorMessage = "A registration for this event with E-Mail " + this.registration.email + " was already found";
      this.loading = false;
      return;
    } else {
      this.registrationService.create(this.registration)
        .then((key:string) => {
          let url = 'https://us-central1-bookr-4231c.cloudfunctions.net/httpEmail';
          let params = {
            to: this.registration.email,
            subject: `Confirm your attendance for: ${this.event?.title} ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}:${this.formatTime(this.event!.end)}`,
            content: `Dear ${this.registration.email}, <br>Thank you for your registration for ${this.event?.title} ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}:${this.formatTime(this.event!.end)}.<br><br>Please click on the link to verify your e-mail adress and attendance. <a href='https://bookr.ctsapprentice.ch/confirm/${key}'>https://bookr.ctsapprentice.ch/confirm/${key}</a><br><br>Kind regards<br>Bookr Team`
          }

          this.anf.httpsCallable('httpEmail')(params)
            .toPromise()
            .then( data => {
              console.log(data);
              if (data.result[0].statusCode == 202) {
                this.loading = false;
                this.successMessage = `A registration confirmation email from sender bookr@ctsapprentice.ch will be sent to your email address. Please review your mailbox, expect a delay of up to 30 min to receive the email.`;
                this.registration.emailSent = true;
                this.registrationService.update(key, this.registration);
              } else {
                this.loading = false;
                this.registrationService.delete(key);
                this.errorMessage = "There was an error sending the E-Mail";
              }
            }).catch((err) => {
              console.error(err); 
              this.loading = false;
              this.errorMessage = JSON.stringify(err);
            })

        }).catch((err) => {
          this.errorMessage = err.message;
          this.loading = false;
          console.error(err);
        })
    }

  }

  public formatDate(dateString:string) {
    let date = new Date(dateString);
    return date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear();
  }

  public formatTime(dateString:string) {
    let date = new Date(dateString);
    let string = ""; 
    if (date.getHours() < 10) {
      string += "0";
    }
    string += date.getHours() + ":";
    if (date.getMinutes() < 10) {
      string += "0";
    }
    string += date.getMinutes();
    return string;
  }
}

