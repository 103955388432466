<div class="container-fluid py-5 px-md-5">
    <div class="row">
        <div class="col-12 mb-3">
            <h2 class="text-md-center">Registration {{event!.title}}<br>{{formatDate(event!.start)}} {{formatTime(event!.start)}}-{{formatTime(event!.end)}} </h2>
        </div>

        <div class="col-md-6 offset-md-3 mb-3">
            <p class="lead">{{event!.description}}</p>
        </div>

        <div class="col-md-6 offset-md-3 mb-3 p-md-5" [formGroup]="registrationForm" *ngIf="registrations.length < 5">
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="alert alert-primary">
                        Please enter your Cognizant email address to register to attend the event
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label class="form-control-label">E-Mail</label>
                        <input formControlName="email" type="text" class="form-control" placeholder="email">
                        <div class="text-danger" *ngIf="submitted && rF.email.errors">Is Required and Must be a Cognizant E-Mail</div>
                    </div>
                </div>

                <div class="col-md-7">
                    <div class="spinner-grow text-primary" role="status" *ngIf="loading">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="alert alert-success" *ngIf="successMessage">
                        {{successMessage}}
                    </div>
                    <div class="alert alert-danger" *ngIf="errorMessage">
                        {{errorMessage}}
                    </div>
                </div>

                <div class="col-md-5 text-right">
                    <button class="btn btn-success" (click)="submit()">Send Verification Mail</button>
                </div>
            </div>
        </div>

        <div class="col-md-6 offset-md-3 mb-3 p-md-5" *ngIf="registrations.length >= 5">
            <div class="row">
                <div class="col-md-8">
                    <div class="alert alert-danger">This event has reached capacity. Please register for another another event or contact <a href="mailto:bookr@ctsapprentice.ch" class="alert-link">bookr@ctsapprentice.ch</a></div>
                </div>
            </div>
        </div>

    </div>

</div>

<section class="bg-light">
    <div class="container py-5">
        <div class="row">
            <div class="col-12 mb-3">
                <h4 class="text-md-center">How does it work?</h4>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="fas fa-file-contract mb-3 fa-3x"></i><br>
                <p>1. Enter E-Mail</p>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="far fa-envelope mb-3 fa-3x"></i><br>
                <p>2. Receive Confirmation</p>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="fas fa-check mb-3 fa-3x"></i><br>
                <p>3. Confirm Your Attendance</p>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="fab fa-microsoft mb-3 fa-3x"></i><br>
                <p>4. Receive Teams Meeting invitation</p>
            </div>
        </div>
    </div>
</section>