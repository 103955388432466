import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { EventService } from '../_services/event.service';
import { Event } from '../_classes/event';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  public events: Event[] = []; 

  constructor(private eventService: EventService, private authService: AuthService) {
    this.eventService.getAll().subscribe((events: any) => {
      this.events = events.filter((e: any) => new Date(e.start) > new Date());
    })
  }

  ngOnInit(): void {
  }

  public formatDate(dateString:string) {
    let date = new Date(dateString);
    return this.getDayName(date.getDay()) + " " + date.getDate() + " " + this.getMonthName(date.getMonth()) + " " + date.getFullYear();
  }

  private getDayName(day: number) {
    switch(day) {
      case 0: 
        return "Sun";
      case 1: 
        return "Mon";
      case 2: 
        return "Tue";
      case 3: 
        return "Wed";
      case 4: 
        return "Thu";
      case 5: 
        return "Fri";
      case 6: 
        return "Sat";
      default: 
        return "";
    }
  }

  private getMonthName(month: number) {
    switch(month) {
      case 0: 
        return "Jan";
      case 1: 
        return "Feb";
      case 2: 
        return "Mar";
      case 3:
        return "Apr";
      case 4: 
        return "May";
      case 5: 
        return "Jun";
      case 6: 
        return "Jul";
      case 7: 
        return "Aug";
      case 8: 
        return "Sep";
      case 9: 
        return "Oct";
      case 10: 
        return "Nov";
      case 11: 
        return "Dec";
      default: 
        return "";
    }
  }

  public formatTime(dateString:string) {
    let date = new Date(dateString);
    let string = ""; 
    if (date.getHours() < 10) {
      string += "0";
    }
    string += date.getHours() + ":";
    if (date.getMinutes() < 10) {
      string += "0";
    }
    string += date.getMinutes();
    return string;
  }

}
