import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/_services/event.service';
import { Event } from 'src/app/_classes/event';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  public eventFormGroup: FormGroup;
  public type = "Create";
  public submitted = false; 
  private key: string | undefined | null;
  public event: Event = {
    title: "",
    description: "",
    start: "",
    end: ""
  }
  public successMessage: string | undefined;
  public errorMessage: string | undefined;

  constructor(private eventService: EventService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.eventFormGroup = this.formBuilder.group({
      title: [this.event.title, [Validators.required]],
      description: [this.event.description, [Validators.required]],
      start: [this.event.start, [Validators.required]],
      end: [this.event.end, [Validators.required]]
    })
    this.key = this.route.snapshot.paramMap.get("id");
    if (this.key) {
      this.type = "Update";
      this.eventService.get(this.key).subscribe((event) => {
        console.log(event);
        this.event = event!;
        this.eF.title.setValue(this.event.title);
        this.eF.description.setValue(this.event.description);
        this.eF.start.setValue(this.event.start);
        this.eF.end.setValue(this.event.end);
      })
    }
  }

  public get eF() { return this.eventFormGroup.controls; }

  public delete() {
    this.eventService.delete(this.key!)
      .then((message) => {
        this.successMessage = message;
      }).catch((err) => {
        this.errorMessage = err.message;
      })
  }

  public submit() {
    this.submitted = true;
    if (this.eventFormGroup.invalid) {
      return;
    }

    this.event.title = this.eF.title.value;
    this.event.description = this.eF.description.value;
    this.event.start = this.eF.start.value;
    this.event.end = this.eF.end.value;

    if (this.type == "Create") {
      this.eventService.create(this.event)
        .then((message) => {
          this.successMessage = message;
        }).catch((err) => {
          this.errorMessage = err.message;
        })

    } 
    if (this.type == "Update") {
        this.eventService.update(this.key!, this.event)
          .then((message) => {
            this.successMessage = message;
          }).catch((err) => {
            this.errorMessage = err.message;
          })
    }
  }

  ngOnInit(): void {
  }

}
