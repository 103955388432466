import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../_services/registration.service';
import { Registration } from '../_classes/registration';
import { take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Event } from '../_classes/event';
import { EventService } from '../_services/event.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  private key: string | null;
  private registration: Registration | null = null;
  private event: Event | null = null;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public loading = false;
  public submitted = false;

  constructor(private registrationService: RegistrationService, private eventService: EventService, private route: ActivatedRoute, private anf: AngularFireFunctions) {
    this.key = this.route.snapshot.paramMap.get("id");
    this.registrationService.get(this.key!).pipe(take(1)).subscribe((r) => {
      if (r == null) {
        this.submitted = true;
        this.errorMessage = "Registration not found."
      } else {
        if (r.emailVerified == true) { 
          this.submitted = true;
          this.errorMessage = "Already verified your E-Mail";
        }
      } 
    });
  }

  verify() {
    this.loading = true;
    this.submitted = true;
    this.registrationService.get(this.key!).pipe(take(1)).subscribe((r) => {
      this.registration = r;
      if (this.registration == null) {
        this.loading = false;
        this.errorMessage = "Registration not found. Please contact 650679@cognizant.com to do your registration manually"
      } else {
        if (this.registration.emailVerified == false) {
          this.registration.emailVerified = true;
          this.registrationService.update(this.key!, this.registration)
            .then(() => {
              this.eventService.get(this.registration!.eventIDFS).pipe(take(1)).subscribe(event => {
                this.event = event;
                if (this.event) {
                  console.log(this.event);
                  let params = {
                    to: 'madaliso.weisweiler@cognizant.com',
                    subject: `${this.registration?.email} registered for ${this.event?.title}`,
                    content: `User: ${this.registration?.email}<br>Event Title: ${this.event?.title}<br>Event Description: ${this.event?.description}<br>Time: ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}-${this.formatTime(this.event!.end)} <br><br>Kind regards<br>Bookr Team`
                  }
                    
                  let params2 = {
                    to: this.registration?.email,
                    subject: `Your Registration for ${this.event?.title} ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}:${this.formatTime(this.event!.end)}`,
                    content: `Dear ${this.registration!.email},<br>You successfully registered for ${this.event?.title} ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}:${this.formatTime(this.event!.end)}<br><br>Event Title: ${this.event?.title}<br><br>Event Description: ${this.event?.description}<br>Time: ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}-${this.formatTime(this.event!.end)}<br>Cancel meeting here: <a href="https://bookr.ctsapprentice.ch/deregister/${this.key}">https://bookr.ctsapprentice.ch/deregister/${this.key}</a><br><br>Kind regards<br>Bookr Team`
                  }

                  this.anf.httpsCallable('httpEmail')(params2)
                    .toPromise()
                    .then(data => {
                      this.loading = false;
                      this.successMessage = "Congratulations, Email verified! You will receive the Outlook invitation before the event. Please note: The Outlook invitation is sent manually, please expect to receive the invitation in 2 working days.";
                      this.anf.httpsCallable('httpEmail')(params)
                      .toPromise()
                      .then(data => {
                      }).catch((err) => {
                        console.error(err);
                        //this.errorMessage = JSON.stringify(err);
                      })
                    }).catch((err) => {
                      console.error(err);
                      this.loading = false;
                      this.errorMessage = JSON.stringify(err);
                    })
                }
              })
            }).catch((err) => {
              this.loading = false;
              this.errorMessage = err.message;
            })
        } else {
          this.loading = false;
          this.errorMessage = "Email Already Verified."
        }
      }
    })
  }

  ngOnInit(): void {
  }

  public formatDate(dateString: string) {
    let date = new Date(dateString);
    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
  }

  public formatTime(dateString: string) {
    let date = new Date(dateString);
    let string = "";
    if (date.getHours() < 10) {
      string += "0";
    }
    string += date.getHours() + ":";
    if (date.getMinutes() < 10) {
      string += "0";
    }
    string += date.getMinutes();
    return string;
  }

}
