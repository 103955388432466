import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  public signInFormGroup: FormGroup;
  public submitted = false;
  public errorMessage = ""; 

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router) {
    this.signInFormGroup = this.formBuilder.group({
      email: [null, [
        Validators.required, 
        Validators.pattern(new RegExp('^[A-Za-z0-9-]*[.]{1}[A-Za-z0-9-]*@cognizant.com$', 'i'))
      ]],
      password: [null, Validators.required]
    })
  }

  public get sF() {
    return this.signInFormGroup.controls;
  }

  public signIn() {
    console.log(this.signInFormGroup);
    this.submitted = true;
    if (this.signInFormGroup.invalid) {
      return; 
    }

    this.authService.signIn(this.sF.email.value, this.sF.password.value)
      .then(() => {
        this.router.navigate(["admin"]);
      }).catch((err) => {
        this.errorMessage = err.message;
      })
  }
  

  ngOnInit(): void {
  }

}
