<div class="container-fluid bg-primary p-5">
    <div class="row mb-3">
        <div class="col-md-12 mb-5 border border-light p-5" [formGroup]="emailFormGroup">
            <div class="row mb-3">
                <div class="col-12">
                    <h1 class="text-light">My Meetings</h1>
                </div>
                <div class="col-12">
                    <p class="lead text-light">Please enter your email address to receive the confirmation of your <u><strong>registration</strong></u> or to <u><strong>deregister</strong></u></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label class="form-control-label text-light">E-Mail</label>
                        <input formControlName="email" type="text" class="form-control" placeholder="email">
                        <div class="text-danger" *ngIf="submitted && eF.email.errors">Is Required and Must be a Cognizant E-Mail</div>
                    </div>
                </div>

                <div class="col-md-8 mb-5">
                    <div class="alert alert-success" *ngIf="successMessage">
                        {{successMessage}}
                    </div>
                    <div class="alert alert-danger" *ngIf="errorMessage">
                        {{errorMessage}}
                    </div>
                </div>

                <div class="col-md-4 text-right mb-5">
                    <button class="btn btn-success" (click)="submit()">Send Mail</button>
                </div>

                <div class="col-12">
                    <div class="alert alert-light" role="alert">
                        <h5 class="alert-heading">How to check my registration</h5>
                        <p><span class="alert-link">Confirmation E-Mail</span>
                            <br> IF SENT: The Confirmation Email is sent and we are waiting for you to verify your E-Mail and attendance with clicking the Link in the mail.
                            <br> IF NOT SENT: The Confirmation Mail could not get sent out, contact <a href="mailto:bookr@ctsapprentice.ch" class="alert-link">bookr@ctsapprentice.ch</a></p>

                        <p><span class="alert-link">E-Mail Confirmed</span>
                            <br> IF CONFIRMED: You have successfully confirmed your attendance. <br> IF NOT CONFIRMED: Action Required; Please click the verification link sent your Inbox from the sender bookr@ctsapprentice.ch</p>

                        <p><span class="alert-link">Deregistered</span><br> IF NO: You did not deregister from the event.<br>If YES: You have deregistered from the event. </p>
                        <hr>
                        <h5 class="alert-heading">My Registration Confirmation</h5>
                        <p>
                            To confirm your attendance, click the verification link in the registration email from sender <a href="mailto:bookr@ctsapprentice.ch" class="alert-link">bookr@ctsapprentice.ch</a><br> If you have not received the registration
                            email, contact <a href="mailto:bookr@ctsapprentice.ch" class="alert-link">bookr@ctsapprentice.ch</a>
                        </p>
                        <hr>
                        <h5 class="alert-heading">My Deregistration</h5>
                        <p>
                            To deregister, click the cancel meeting link in the registration email from sender <a href="mailto:bookr@ctsapprentice.ch" class="alert-link">bookr@ctsapprentice.ch</a> If you have not received the registration email, contact
                            <a href="mailto:bookr@ctsapprentice.ch" class="alert-link">bookr@ctsapprentice.ch</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>