import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Registration } from '../_classes/registration';
import { EventService } from '../_services/event.service';
import { RegistrationService } from '../_services/registration.service';

@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.css']
})
export class FindComponent implements OnInit {

  public emailFormGroup: FormGroup;
  public registrations: any[] = [];
  public events = new Map(); 
  public submitted = false;
  public successMessage: string | undefined;
  public errorMessage: string | undefined;

  constructor(private anf: AngularFireFunctions, private registrationService: RegistrationService, private eventService: EventService, private formBuilder: FormBuilder) {
    this.emailFormGroup = this.formBuilder.group({
      email: [null, [Validators.required, 
        //Validators.pattern(new RegExp('^[A-Za-z0-9-]*[.]{1}[A-Za-z0-9-]*@cognizant.com$', 'i'))
      ]]
    })
  }

  public get eF() {
    return this.emailFormGroup.controls;
  }

  public submit() {
    this.submitted = true; 
    if (this.emailFormGroup.invalid)  {
      return;
    }

    this.registrationService.getFromEmail(this.eF.email.value.toLowerCase()).subscribe(async(registrations) => {
      this.registrations = registrations;
      if (this.registrations.length == 0) {
        this.errorMessage = "No Registration found"
      } else {
        this.getEvents().then(() => {
          console.log(this.events);
          if (this.events.size == 0) {
            this.errorMessage = `No Registration found for user ${this.eF.email.value.toLowerCase()}`;
          }
          this.sendMail(this.eF.email.value.toLowerCase());
      });
      }
    })
  }

  private getEvents(): Promise<any> {
    return new Promise<any>((res, rej) => {
      this.registrations.forEach((r:Registration, index, array) => {
        this.eventService.get(r.eventIDFS).pipe(take(1)).subscribe(e => { 
          if (e !== null) {
            this.events.set(r.eventIDFS, {event: e, registration: r});
          }
          if (index === array.length -1) {
            res("");
          }
        })
      });
    })
  }

  private sendMail(email: string) {
    let params = {
      to: email,
      subject: `Your Bookr Registrations`,
      content: `You (${email}) have registered for the following events on bookr.ctsapprentice.ch<br><br>`
    }

    this.events.forEach((val, key) => {
      params.content += this.textFromRegistration(val);
      console.log(0);
    })

    console.log(params);

    params.content += "<br>Kind regards:<br>Bookr Team";

    this.anf.httpsCallable('httpEmail')(params)
      .toPromise()
      .then(data => {
        this.successMessage = `Successfully sent (${email}) all meeting  registration and deregistration details. Please review your mailbox, expect a delay of up to 30 min to receive the email`;
      }).catch((err) => {
        console.error(err);
      })
  }

  private textFromRegistration(val: any) {
    let string = `${val.event.title} on ${this.formatDate(val.event.start)} at ${this.formatTime(val.event.start)}-${this.formatTime(val.event.end)}<br>`;
    if (val.registration.emailSent) {
      string += "Confirmation E-Mail: SENT<br>";
    } else {
      string += "Confirmation E-Mail: NOT SENT<br>";
    }

    if (val.registration.emailVerified) {
      string += "E-Mail confirmed: CONFIRMED<br>";
    } else {
      string += "E-Mail confirmed: NOT CONFIRMED<br>";
    }

    if (val.registration.deregistered) {
      string += "Deregistered: YES<br><br>";
    } else {
      string += `Deregistered: NO<br><a href='https://bookr.ctsapprentice.ch/deregister/${val.registration.key}'>https://bookr.ctsapprentice.ch/deregister/${val.registration.key}</a><br><br>`;
    }

    return string;
  }

  public formatDate(dateString:string) {
    let date = new Date(dateString);
    return this.getDayName(date.getDay()) + " " + date.getDate() + " " + this.getMonthName(date.getMonth()) + " " + date.getFullYear();
  }

  public formatTime(dateString:string) {
    let date = new Date(dateString);
    let string = ""; 
    if (date.getHours() < 10) {
      string += "0";
    }
    string += date.getHours() + ":";
    if (date.getMinutes() < 10) {
      string += "0";
    }
    string += date.getMinutes();
    return string;
  }

  public getTimeText(dateString:string) {
    var diffDays = Math.ceil(Math.abs(new Date(dateString).valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24)); 
    if (new Date(dateString) > new Date()) {
      return "in " + diffDays + " days";
    } else {
      return diffDays + " days ago";
    }
  }

  private getDayName(day: number) {
    switch(day) {
      case 0: 
        return "Sun";
      case 1: 
        return "Mon";
      case 2: 
        return "Tue";
      case 3: 
        return "Wed";
      case 4: 
        return "Thu";
      case 5: 
        return "Fri";
      case 6: 
        return "Sat";
      default: 
        return "";
    }
  }

  private getMonthName(month: number) {
    switch(month) {
      case 0: 
        return "Jan";
      case 1: 
        return "Feb";
      case 2: 
        return "Mar";
      case 3:
        return "Apr";
      case 4: 
        return "May";
      case 5: 
        return "Jun";
      case 6: 
        return "Jul";
      case 7: 
        return "Aug";
      case 8: 
        return "Sep";
      case 9: 
        return "Oct";
      case 10: 
        return "Nov";
      case 11: 
        return "Dec";
      default: 
        return "";
    }
  }

  public getDescriptionText(r: Registration) {
    let text = r.email + "\n"; 
    if (r.emailSent) {
      text += "Confirmation Email: SENT\n";
    } else {
      text += "Confirmation Email: NOT SENT\n";
    }
    if(r.emailVerified) {
      text+= "Email Confirmed: CONFIRMED\n";
    } else {
      text+= "Email Confirmed: NOT CONFIRMED\n";
    }
    if (r.deregistered) {
      text+= "Deregistered: YES"
    } else {
      text+= "Deregistered: NO"
    }
    return text;
  }

  ngOnInit(): void {
  }

}
