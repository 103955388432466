<div class="container my-5">
    <div class="row mb-3">
        <div class="col">
            <h1 class="text-md-center">Tables of 5</h1>
            <p class="lead">The “Table of 5” meeting series strives to promote open dialogue between Associates and members of the Management Team as part of the broader Switzerland employee engagement activities. The meetings are opportunity for Associates to informally
                discuss with members of the Management Team, how to improve the way of working and culture at Cognizant.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" *ngFor="let event of events" routerLink="/events/{{event.key}}">
            <div class="card text-primary bg-light mb-3">
                <div class="card-body my-3">
                    <h5 class="text-primary">{{event.title}}<br><small class="text-success">{{formatDate(event.start)}} {{formatTime(event.start)}}-{{formatTime(event.end)}}</small></h5>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="bg-light">
    <div class="container py-5">
        <div class="row">
            <div class="col-12 mb-3">
                <h4 class="text-md-center">How does it work?</h4>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="fas fa-file-contract mb-3 fa-3x"></i><br>
                <p>1. Enter E-Mail</p>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="far fa-envelope mb-3 fa-3x"></i><br>
                <p>2. Receive Confirmation</p>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="fas fa-check mb-3 fa-3x"></i><br>
                <p>3. Confirm Your Attendance</p>
            </div>
            <div class="col-md-3 text-md-center mb-3">
                <i class="fab fa-microsoft mb-3 fa-3x"></i><br>
                <p>4. Receive Teams Meeting invitation</p>
            </div>
        </div>
    </div>
</section>