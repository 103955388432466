import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public onAuthStateChanged = this.auth.onAuthStateChanged;

  constructor(private auth: AngularFireAuth) {}

  public signIn(email: string, password: string) {
    return new Promise((res, rej) => {
      this.auth.signInWithEmailAndPassword(email, password)
        .then((result) => {
          console.log(result);
          res(result);
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }
}
