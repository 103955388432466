import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Event } from '../_classes/event';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private db: AngularFireDatabase) { }

  public create(event: Event): Promise<string> {
    return new Promise((res, rej) => {
      this.db.list("/events/").push(event)
        .then(() => {
          res("Successfully created " + event.title)
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }

  public getAll() {
    return this.db.list<Event>("/events/").snapshotChanges().pipe(
      map(events => 
        events.map(event => (
          {
            key: event.payload.key,
            ...event.payload.val() 
          }
        ))
      )
    );
  }

  public get(key: string) {
    return this.db.object<Event>("/events/" + key).valueChanges();
  }

  public update(key: string, event: Event): Promise<string> {
    return new Promise((res, rej) => {
      this.db.list("/events/").set(key, event)
        .then(() => {
          res("Successfully updated " + event.title)
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }

  public delete(key: string): Promise<string> {
    return new Promise((res, rej) => {
      this.db.list("/events/").remove(key)
        .then(() => {
          res("Successfully deleted Event")
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }
}
