<div class="container my-5">
    <div class="row my-5">
        <div class="col-md-6 offset-md-3">
            <div class="card text-white bg-primary mb-3">
                <div class="card-header">{{type}} Event</div>
                <div class="card-body">
                    <div class="row" [formGroup]="eventFormGroup">
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label class="form-control-label">Title</label>
                                <input formControlName="title" type="text" class="form-control" placeholder="title">
                                <div class="text-danger" *ngIf="submitted && eF.title.errors">Is Required and Must be a cognizant E-Mail</div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label class="form-control-label">Description</label>
                                <input formControlName="description" type="text" class="form-control" placeholder="Enter Description here">
                                <div class="text-danger" *ngIf="submitted && eF.description.errors">Is Required</div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label class="form-control-label">Start</label>
                                <input formControlName="start" type="datetime-local" class="form-control">
                                <div class="text-danger" *ngIf="submitted && eF.start.errors">Is Required</div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label class="form-control-label">End</label>
                                <input formControlName="end" type="datetime-local" class="form-control">
                                <div class="text-danger" *ngIf="submitted && eF.end.errors">Is Required</div>
                            </div>
                        </div>

                        <div class="col-12 mb-3 alert alert-success" *ngIf="successMessage">
                            {{successMessage}}
                        </div>

                        <div class="col-12 mb-3 alert alert-danger" *ngIf="errorMessage">
                            {{errorMessage}}
                        </div>

                        <div class="col-12 mb-3">
                            <button (click)="submit()" class="btn btn-success">Submit</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>