import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../_services/registration.service';
import { Registration } from '../_classes/registration';
import { take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Event } from '../_classes/event';
import { EventService } from '../_services/event.service';
@Component({
  selector: 'app-deregister',
  templateUrl: './deregister.component.html',
  styleUrls: ['./deregister.component.css']
})
export class DeregisterComponent implements OnInit {

  private key: string | null;
  private registration: Registration | null = null;
  private event: Event | null = null;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  public loading = false;
  public submitted = false;

  constructor(private registrationService: RegistrationService, private eventService: EventService, private route: ActivatedRoute, private anf: AngularFireFunctions) {
    this.key = this.route.snapshot.paramMap.get("id");
    this.registrationService.get(this.key!).pipe(take(1)).subscribe((r) => {
      if (r == null) {
        this.submitted = true;
        this.errorMessage = "No Registration found.";
      } else {
        if (r.deregistered == true) { 
          this.submitted = true;
          this.errorMessage = "Already deregistered from this event";
        }
      }
    })
  }

  deregister() {
    this.submitted = true;
    this.loading = true;
    this.registrationService.get(this.key!).pipe(take(1)).subscribe((r) => {
      this.registration = r;
      if (this.registration == null) {
        this.loading = false;
        this.errorMessage = "Registration not found."
      } else {
        if (this.registration.deregistered == false) {
          this.registration.deregistered = true;
          this.registrationService.update(this.key!, this.registration)
            .then(() => {
              this.eventService.get(this.registration!.eventIDFS).pipe(take(1)).subscribe(event => {
                this.event = event;
                if (this.event) {
                  console.log(this.event);
                  let params = {
                    to: 'madaliso.weisweiler@cognizant.com',
                    subject: `${this.registration?.email} deregistered from ${this.event?.title}`,
                    content: `User: ${this.registration?.email} deregistered from ${this.event?.title}<br>Event Title: ${this.event?.title}<br>Event Description: ${this.event?.description}<br>Time: ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}-${this.formatTime(this.event!.end)}<br><br>Kind regards<br>Bookr Team`
                  }

                  let params2 = {
                    to: this.registration?.email,
                    subject: `Deregistered from Event: ${this.event?.title} ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}:${this.formatTime(this.event!.end)}`,
                    content: `You successfully deregistered from the event:<br> ${this.event?.title}, ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}-${this.formatTime(this.event!.end)}<br><br>Kind regards<br>Bookr Team`
                  }

                  this.anf.httpsCallable('httpEmail')(params2)
                    .toPromise()
                    .then(data => {
                      this.loading = false;
                      this.successMessage = `Successfully deregistered from Event: ${this.event?.title}, ${this.formatDate(this.event!.start)} ${this.formatTime(this.event!.start)}-${this.formatTime(this.event!.end)}`;
                      
                      this.anf.httpsCallable('httpEmail')(params)
                      .toPromise()
                      .then(data => {
                      }).catch((err) => {
                        console.error(err);
                      })
                    }).catch((err) => {
                      console.error(err);
                    })
                }
              })
            }).catch((err) => {
              this.loading = false;
              this.errorMessage = err.message;
            })
        } else {
          this.loading = false;
          this.errorMessage = "You already deregistered from the Event. "
        }
      }
    })
  }

  ngOnInit(): void {
  }

  public formatDate(dateString: string) {
    let date = new Date(dateString);
    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
  }

  public formatTime(dateString: string) {
    let date = new Date(dateString);
    let string = "";
    if (date.getHours() < 10) {
      string += "0";
    }
    string += date.getHours() + ":";
    if (date.getMinutes() < 10) {
      string += "0";
    }
    string += date.getMinutes();
    return string;
  }

}
