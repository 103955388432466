import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DeregisterComponent } from './deregister/deregister.component';
import { CreateComponent } from './events/create/create.component';
import { DetailViewComponent } from './events/detail-view/detail-view.component';
import { EventsComponent } from './events/events.component';
import { FindComponent } from './find/find.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);

const routes: Routes = [
  { path: "", component: HomeComponent},
  { path: "sign-in", component: SignInComponent},
  { path: "events", component: EventsComponent},
  { path: "events/:id", component: DetailViewComponent},
  { path: "find", component: FindComponent},
  { path: "confirm/:id", component: ConfirmComponent},
  { path: "edit", component: CreateComponent },
  { path: "edit/:id", component: CreateComponent },
  { path: "admin", component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: "deregister/:id", component: DeregisterComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
