import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Registration } from 'src/app/_classes/registration';
import { EventService } from 'src/app/_services/event.service';
import { RegistrationService } from 'src/app/_services/registration.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public registrations: any[] = [];
  public events: any[] = [];
  public errorMessage: string | undefined;
  public successMessage: string | undefined;
  public eventMap = new Map(); 


  constructor(private registrationService: RegistrationService, private eventService: EventService) {
    this.registrationService.getAll().subscribe(registrations => {
      this.registrations = registrations;
      if (this.registrations.length == 0) {
        this.errorMessage = "No Registration found"
      } else {
        this.eventService.getAll().pipe(take(1)).subscribe(events => {
          this.events = events;
          this.events.forEach(e => {
            this.eventMap.set(e.key, { event: e, registrations: this.registrations.filter(r => { return r.eventIDFS == e.key })});
          })
        })
        this.successMessage = this.registrations.length + " Registration(s) found";
      }
    })
  }

  ngOnInit(): void {
  }

  public delete(key: string) {
    this.eventService.delete(key)
      .then(result => {
        alert(result);
        this.eventMap.delete(key);
      }).catch((err) => {
        console.warn(err);
      })
  }

  public nrOfRegisteredUser() {
    return this.registrations.filter(r => { return r.emailVerified == true }).length;
  }

  public nrOfDeregistrations() {
    return this.registrations.filter(r => { return r.deregistered == true }).length;

  }

  public formatDate(dateString:string) {
    let date = new Date(dateString);
    return date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear();
  }

  public formatTime(dateString:string) {
    let date = new Date(dateString);
    let string = ""; 
    if (date.getHours() < 10) {
      string += "0";
    }
    string += date.getHours() + ":";
    if (date.getMinutes() < 10) {
      string += "0";
    }
    string += date.getMinutes();
    return string;
  }

  public getTimeText(dateString:string) {
    var diffDays = Math.ceil(Math.abs(new Date(dateString).valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24)); 
    if (new Date(dateString) > new Date()) {
      return "in " + diffDays + " days";
    } else {
      return diffDays + " days ago";
    }
  }

  public getDescriptionText(r: Registration) {
    let text = r.email + " "; 
    if (r.emailSent) {
      text += "Invitation Email: Sent | ";
    } else {
      text += "Invitation Email: NOT Sent | ";
    }
    if(r.emailVerified) {
      text+= "Email Confirmed: Confirmed | ";
    } else {
      text+= "Email Confirmed: NOT Confirmed | ";
    }
    if (r.deregistered) {
      text+= "Deregistered: YES"
    } else {
      text+= "Deregistered: NO"
    }
    return text;
  }
}
