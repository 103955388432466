<section class="bg-light py-5">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-12 mb-3">
                <h1 class="text-md-center">Confirm your E-Mail and Attendance</h1>
            </div>
            <div class="col-12 mb-3">
                <div class="text-md-center" *ngIf="!submitted">
                    <button class="btn btn-primary" (click)="verify()">Verify E-Mail</button>
                </div>
            </div>

            <div class="col-12 mb-3" *ngIf="loading">
                <div class="d-flex justify-content-center">
                    <div class="spinner-grow text-primary mb-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-3" *ngIf="successMessage">
                <div class="alert alert-success mb-3">
                    {{successMessage}}
                </div>
            </div>

            <div class="col-12 mb-3" *ngIf="errorMessage">
                <div class="alert alert-danger">
                    {{errorMessage}}
                </div>
            </div>
        </div>
    </div>
</section>