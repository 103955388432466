<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <a class="navbar-brand" routerLink="/">
        <img src="https://www.cognizant.com/content/dam/cognizant/en_us/dotcom/logos/ai/cog-logo-white.svg" class="mr-3" width="auto" height="32" alt="">Bookr
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor02">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
                <a class="nav-link" routerLink="/">Home</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" routerLink="/events">Events</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" routerLink="/events">Register</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" routerLink="/find">My Meetings</a>
            </li>
        </ul>
    </div>
</nav>