<div class="container my-5">
    <div class="row my-5">
        <div class="col-md-6 offset-md-3">
            <div class="card text-white bg-primary mb-3">
                <div class="card-header">Sign In</div>
                <div class="card-body">
                    <div class="row" [formGroup]="signInFormGroup">
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label class="form-control-label">E-Mail</label>
                                <input formControlName="email" type="text" class="form-control" placeholder="email">
                                <div class="text-danger" *ngIf="submitted && sF.email.errors">Is Required and Must be a cognizant E-Mail</div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="form-group">
                                <label class="form-control-label">Password</label>
                                <input formControlName="password" type="password" class="form-control" placeholder="*********">
                                <div class="text-danger" *ngIf="submitted && sF.password.errors">Is Required</div>
                            </div>
                        </div>
                        <div class="col-12 mb-3" *ngIf="errorMessage != ''">
                            <p class="text-danger">{{errorMessage}}</p>
                        </div>

                        <div class="col-12 mb-3">
                            <button (click)="signIn()" class="btn btn-success">Sign In</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>