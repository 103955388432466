<div class="container-fluid p-md-5">
    <div class="row">
        <div class="col">
            <div class="jumbotron">
                <h1 class="display-6">Book a Table of 5 with the Leaders of Cognizant Switzerland</h1>
                <p class="lead">Welcome to Bookr the online registration portal for meetings and events hosted by the Switzerland Management Team.</p>
                <div class="btn-group d-none d-md-block" role="group" aria-label="Basic example">
                    <a class="btn btn-primary" routerLink="/events" role="button">Register for a Meeting</a>
                    <a class="btn btn-outline-primary ml-md-3" routerLink="/events" role="button">Events</a>
                    <a class="btn btn-outline-primary ml-md-3" routerLink="/find" role="button">Find my Meeting</a>
                </div>
                <div class="d-md-none">
                    <a class="btn btn-primary my-1" routerLink="/events" role="button">Register for a Meeting</a><br>
                    <a class="btn btn-outline-primary my-1" routerLink="/events" role="button">Events</a><br>
                    <a class="btn btn-outline-primary my-1" routerLink="/find" role="button">Find my Meeting</a>
                </div>
                <hr class="my-4">

                <div class="row my-5">
                    <div class="col-12 mb-3">
                        <h4>How does it work?</h4>
                    </div>
                    <div class="col-md-3 text-md-center mb-3">
                        <i class="fas fa-file-contract mb-3 fa-3x"></i><br>
                        <p>1. Enter E-Mail</p>
                    </div>
                    <div class="col-md-3 text-md-center mb-3">
                        <i class="far fa-envelope mb-3 fa-3x"></i><br>
                        <p>2. Receive Confirmation</p>
                    </div>
                    <div class="col-md-3 text-md-center mb-3">
                        <i class="fas fa-check mb-3 fa-3x"></i><br>
                        <p>3. Confirm Your Attendance</p>
                    </div>
                    <div class="col-md-3 text-md-center mb-3">
                        <i class="fab fa-microsoft mb-3 fa-3x"></i><br>
                        <p>4. Receive Teams Meeting invitation</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>