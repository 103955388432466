import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Registration } from '../_classes/registration';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private db: AngularFireDatabase) { }

  public create(registration: Registration): Promise<string> {
    return new Promise((res, rej) => {
      this.db.list("/registrations/").push(registration)
        .then((ref) => {
          res(ref.key!);
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }

  public getAll() {
    return this.db.list<Registration>("/registrations/").snapshotChanges().pipe(
      map(registrations => 
        registrations.map(registration => (
          {
            key: registration.payload.key,
            ...registration.payload.val() 
          }
        ))
      )
    );
  }

  public getFromEvent(key: string) {
    return this.db.list<Registration>("/registrations/", ref => { return ref.orderByChild("eventIDFS").equalTo(key)}).snapshotChanges().pipe(
      map(registrations => 
        registrations.map(registration => (
          {
            key: registration.payload.key,
            ...registration.payload.val() 
          }
        ))
      )
    );  
  }

  public getFromEmail(email: string) {
    return this.db.list<Registration>("/registrations/", ref => { return ref.orderByChild("email").equalTo(email)}).snapshotChanges().pipe(
      map(registrations => 
        registrations.map(registration => (
          {
            key: registration.payload.key,
            ...registration.payload.val() 
          }
        ))
      )
    );  
  }

  public get(key: string) {
    return this.db.object<Registration>("/registrations/" + key).valueChanges();
  }

  public update(key: string, registration: Registration) {
    return new Promise((res, rej) => {
      this.db.list("/registrations/").set(key, registration)
        .then(() => {
          res("Successfully updated ")
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }

  public delete(key: string) {
    return new Promise((res, rej) => {
      this.db.list("/registrations/").remove(key)
        .then(() => {
          res("Successfully deleted Registration")
        }).catch((err) => {
          console.error(err);
          rej(err);
        })
    })
  }
}
